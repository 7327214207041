import axios from 'axios';
import {
  API_CALL_ERROR,
  GET_APPROVED_CASH_SALES_ENDPOINT,
  GET_CASH_SALES_ENDPOINT,
  SAVE_CASH_SALES_ENDPOINT,
} from './_constants';

export async function getCashSales(month, building) {
  const qs = require('qs');
  try {
    const { data } = await axios.post(
      GET_CASH_SALES_ENDPOINT,
      qs.stringify({
        month: month,
        building: building,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function saveCashSales(userId, inventoryList) {
  const payload = {
    userId: userId,
    invList: inventoryList,
  };
  try {
    const { data } = await axios.post(SAVE_CASH_SALES_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getApprovedCashSales(month, building) {
  const qs = require('qs');
  try {
    const { data } = await axios.post(
      GET_APPROVED_CASH_SALES_ENDPOINT,
      qs.stringify({
        month: month,
        building: building,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function saveUploadedCashSales(inventoryList) {
  const payload = {
    invList: inventoryList,
  };
  try {
    const { data } = await axios.post(SAVE_CASH_SALES_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
