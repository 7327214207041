import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ConfirmationModal, Selection } from '../../../components';
import { UserContext } from '../../../utils/contexts/UserContext';
import { months } from '../../../utils/data/Months';
import { years } from '../../../utils/data/Years';
import { Table } from 'antd';
import { cashSaleTableHeader } from '../../../utils/tableData/CashSalesTableHeader';
import { saveCashSales } from '../../../utils/api/CashSalesAPI';

export default function CashSalesForApproval() {
  const {
    profile,
    cashSalesForApproval,
    loadCashSalesForApproval,
    buildings,
    loadBuildings,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [localCashSaleList, setLocalCashSaleList] = useState([{}]);
  const [localBuildingsList, setLocalBuildingsList] = useState([]);

  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const data = localCashSaleList.map((data, index) => {
    return { ...data, key: index };
  });

  const handleApprove = async () => {
    setIsLoading(true);
    setIsConfirmationModalOpen(false);
    let cashSalesStatus = [];

    localCashSaleList.forEach((record) => {
      const recordMatched = selectedRows.find(
        (selected) => selected.invoiceId === record.invoiceId
      );
      if (recordMatched !== null && recordMatched !== undefined) {
        const dataRecord = {
          invoiceId: recordMatched.invoiceId,
          invBuilding: recordMatched.invBuilding,
          invoiceNumber: recordMatched.invoiceNumber,
          externalId: recordMatched.ExternalID,
          month: recordMatched.month,
          status: 1,
        };
        cashSalesStatus.push(dataRecord);
      } else {
        const dataRecord = {
          invoiceId: record.invoiceId,
          invBuilding: record.invBuilding,
          invoiceNumber: record.invoiceNumber,
          externalId: record.ExternalID,
          month: record.month,
          status: 2,
        };
        cashSalesStatus.push(dataRecord);
      }
    });

    const response = await saveCashSales(
      parseInt(profile.userId),
      cashSalesStatus
    );

    if (response.isSuccessful) {
      resetData();
    } else {
      // TODO create an error handler
      alert(
        'An error occured. Please contact system administrator immediately.'
      );
    }

    setIsLoading(false);
  };

  const resetData = () => {
    setSelectedBuilding('');
    setSelectedMonth('');
    setSelectedYear('');

    setLocalCashSaleList([]);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    setLocalCashSaleList(cashSalesForApproval);
  }, [cashSalesForApproval]);

  useEffect(() => {
    setLocalBuildingsList(buildings);
  }, [buildings]);

  useEffect(() => {
    if (buildings.length === 0) {
      loadBuildings();
    }
  }, []);

  return (
    <Container className="pt-3">
      <h2>Cash Sales - For Approval</h2>
      <hr />
      <Row className="align-items-center">
        <Col xs={3}>
          <Selection
            title={'Building'}
            disabled={isLoading}
            value={selectedBuilding}
            onChange={(e) => {
              setSelectedBuilding(e.target.value);
            }}
            options={localBuildingsList.map((building) => ({
              value: building.buildingCode,
              name: building.buildingName,
            }))}
          />
        </Col>
        <Col>
          <Selection
            title={'Month'}
            disabled={isLoading}
            value={selectedMonth}
            onChange={(e) => {
              setSelectedMonth(e.target.value);
            }}
            options={months.map((month) => ({
              value: month.value,
              name: month.name,
            }))}
          />
        </Col>
        <Col>
          <Selection
            title={'Year'}
            disabled={isLoading}
            value={selectedYear}
            onChange={(e) => {
              setSelectedYear(e.target.value);
            }}
            options={years.map((year) => ({
              value: year.value,
              name: year.name,
            }))}
          />
        </Col>
        <Col>
          <Button
            variant="secondary"
            disabled={
              isLoading ||
              selectedBuilding === '' ||
              selectedMonth === '' ||
              selectedYear === ''
            }
            onClick={async () => {
              setIsLoading(true);
              setSelectedRows([]);
              setSelectedRowKeys([]);
              const date = selectedYear + '-' + selectedMonth;
              await loadCashSalesForApproval(date, selectedBuilding);
              setIsLoading(false);
            }}
            className="mb-3 w-100"
          >
            {isLoading ? 'Loading...' : 'Filter'}
          </Button>
        </Col>
        <Row className="pe-0">
          <Col className="w-100 text-center pe-0">
            {selectedRows.length > 0 && (
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={() => setIsConfirmationModalOpen(true)}
                className="mb-3 w-100 pms-bg"
              >
                <strong>{isLoading ? 'Saving...' : 'Approve!'}</strong>
              </Button>
            )}
          </Col>
        </Row>
      </Row>
      <Table
        columns={cashSaleTableHeader}
        dataSource={data}
        title={() => 'For Approval'}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRows(newSelectedRows);
            setSelectedRowKeys(newSelectedRowKeys);
          },
        }}
      />
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setIsConfirmationModalOpen}
        title={'Confirm Cash Sales approval.'}
        body={'NOTE: All unchecked rows will be marked as DENIED.'}
        handleConfirm={handleApprove}
      />
    </Container>
  );
}
