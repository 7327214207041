import React, { useContext, useEffect } from 'react';
import { Link, redirect, useLocation } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import './Navigation.css';
import SubMenu from './SubMenu';
import Menu from './Menu';
import { RiLockPasswordLine } from 'react-icons/ri';
import { HiOutlineLogout } from 'react-icons/hi';
import { AiOutlineBars } from 'react-icons/ai';
import { BiGasPump } from 'react-icons/bi';
import { GlobalContext } from '../../App';
import {
  backToPMSNavigation,
  forApprovalSubMenuNavigation,
  reportSubMenuNavigation,
  uploadToNetSuiteSubMenuNavigation,
} from '../../utils/data/navigation';
import { HOME_ROUTE } from '../../utils/routers/RouteConstants';
import { UserContext } from '../../utils/contexts/UserContext';

export default function Navigation({ children }) {
  const currentLocation = useLocation();

  const { profile } = useContext(UserContext);
  const { setIsLoggedIn } = useContext(GlobalContext);

  return (
    <>
      <header>
        {/* SIDE-BAR NAVIGATION */}
        <nav id="sidebarMenu" className="collapse d-lg-block sidebar bg-white">
          <div className="position-sticky">
            <div className="list-group list-group-flush mx-3 mt-4">
              <SubMenu
                nav={forApprovalSubMenuNavigation}
                currentLocation={currentLocation}
              />
              <SubMenu
                nav={uploadToNetSuiteSubMenuNavigation}
                currentLocation={currentLocation}
              />
              <Menu
                info={backToPMSNavigation}
                currentLocation={currentLocation}
              />
            </div>
          </div>
        </nav>

        {/* TOP-BAR NAVIGATION */}
        <div
          id="main-navbar"
          className="navbar navbar-expand-lg navbar-dark fixed-top pms-bg"
        >
          <div className="container-fluid px-5">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#sidebarMenu"
              aria-controls="sidebarMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <AiOutlineBars className="me-2" size={20} />
            </button>

            <Link to={HOME_ROUTE} className="navbar-brand">
              {/* <BiGasPump className="ms-3 me-1" size={25} /> */}
              <span>PMS - NetSuite</span>
            </Link>

            <NavDropdown
              title={`Hi, ${profile.name}`}
              id="basic-nav-dropdown"
              className="text-white mx-3"
            >
              <NavDropdown.Item
                to={HOME_ROUTE}
                onClick={() => {
                  redirect(HOME_ROUTE);
                }}
                className={'list-group-item list-group-item-action p-2 ripple'}
              >
                <RiLockPasswordLine className="me-2" size={20} />
                <span>Change Password</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                to={HOME_ROUTE}
                onClick={() => {
                  setIsLoggedIn(false);
                }}
                className={'list-group-item list-group-item-action p-2 ripple'}
              >
                <HiOutlineLogout className="me-2" size={20} />
                <span>Sign Out</span>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </header>
      <main style={{ marginTop: '58px' }}>
        <div className="m-5">{children}</div>
      </main>
    </>
  );
}
