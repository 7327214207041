import axios from 'axios';
import { API_CALL_ERROR, LOGIN_ENDPOINT } from './_constants';

export async function getAuthTokenNetSuite(code) {
  const clientId =
    'de57d0fcb3728fc777324ce1e7138f9e5e2a848a4bbbe4754b157f406cc6cc3f';
  const clientSecret =
    '18faeffb6f4bb5c025a4a9d4a9a7f0b6f6be3630efda59745d16c2fc2af7085c';
  const redirectUri = 'https%3A%2F%2Fpms-netsuite.xgitcorp.com';
  const authorizationCode = code;

  const params = {
    grant_type: 'authorization_code',
    code: authorizationCode,
    redirect_uri: redirectUri,
    client_id: clientId,
    client_secret: clientSecret,
  };

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const urlencoded = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    urlencoded.append(key, value);
  }

  fetch(
    'https://5887473-sb1.suitetalk.api.netsuite.com/services/rest/auth/oauth2/v1/token',
    {
      method: 'POST',
      headers: headers,
      body: urlencoded,
    }
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      // The access token is in the "access_token" property of the response object
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  //   console.log(code);
  //   const qs = require('qs');
  //   try {
  //     const { data } = await axios.post(
  //       'https://5887473-sb1.suitetalk.api.netsuite.com/services/rest/auth/oauth2/v1/token',
  //       qs.stringify({
  //         code: code,
  //         redirect_uri: 'https%3A%2F%2Fpms-netsuite.xgitcorp.com',
  //         grant_type: 'authorization_code',
  //         client_id:
  //           'de57d0fcb3728fc777324ce1e7138f9e5e2a848a4bbbe4754b157f406cc6cc3f',
  //         client_secret:
  //           '18faeffb6f4bb5c025a4a9d4a9a7f0b6f6be3630efda59745d16c2fc2af7085c',
  //       }),
  //       {
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //           accept: 'application/json',
  //         },
  //       }
  //     );
  //     console.log(data);
  //     return data;
  //   } catch (error) {
  //     console.log(error);
  //     return { isSuccessful: false, data: API_CALL_ERROR };
  //   }
}
