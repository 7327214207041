import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/contexts/UserContext';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ConfirmationModal, Selection } from '../../components';
import { months } from '../../utils/data/Months';
import { years } from '../../utils/data/Years';
import { invoiceTableHeader } from '../../utils/tableData/InvoiceTableHeader';
import { Table } from 'antd';

export default function InvoiceUploading() {
  const {
    profile,
    approvedInvoicesList,
    loadApprovedInvoices,
    buildings,
    loadBuildings,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [localApprovedInvoices, setLocalApprovedInvoices] = useState([]);

  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const [localBuildingsList, setLocalBuildingsList] = useState([]);

  const data = localApprovedInvoices.map((data, index) => {
    return { ...data, key: index };
  });

  const handleUploadToNetSuite = async () => {
    setIsLoading(true);
    setIsConfirmationModalOpen(false);
    // TODO Handle the uploading to NetSuite.

    setIsLoading(false);
  };

  const resetData = () => {
    setSelectedBuilding('');
    setSelectedMonth('');
    setSelectedYear('');

    setLocalApprovedInvoices([]);
  };

  useEffect(() => {
    setLocalApprovedInvoices(approvedInvoicesList);
  }, [approvedInvoicesList]);

  useEffect(() => {
    setLocalBuildingsList(buildings);
  }, [buildings]);

  useEffect(() => {
    if (buildings.length === 0) {
      loadBuildings();
    }
  }, []);

  return (
    <Container className="pt-3">
      <h2>Invoices - Upload to NetSuite</h2>
      <hr />
      <Row className="align-items-center">
        <Col xs={3}>
          <Selection
            title={'Building'}
            disabled={isLoading}
            value={selectedBuilding}
            onChange={(e) => {
              setSelectedBuilding(e.target.value);
            }}
            options={localBuildingsList.map((building) => ({
              value: building.buildingCode,
              name: building.buildingName,
            }))}
          />
        </Col>
        <Col>
          <Selection
            title={'Month'}
            disabled={isLoading}
            value={selectedMonth}
            onChange={(e) => {
              setSelectedMonth(e.target.value);
            }}
            options={months.map((month) => ({
              value: month.value,
              name: month.name,
            }))}
          />
        </Col>
        <Col>
          <Selection
            title={'Year'}
            disabled={isLoading}
            value={selectedYear}
            onChange={(e) => {
              setSelectedYear(e.target.value);
            }}
            options={years.map((year) => ({
              value: year.value,
              name: year.name,
            }))}
          />
        </Col>
        <Col>
          <Button
            variant="secondary"
            disabled={
              isLoading ||
              selectedBuilding === '' ||
              selectedMonth === '' ||
              selectedYear === ''
            }
            onClick={async () => {
              setIsLoading(true);
              const date = selectedYear + '-' + selectedMonth;
              await loadApprovedInvoices(date, selectedBuilding);
              setIsLoading(false);
            }}
            className="mb-3 w-100"
          >
            {isLoading ? 'Loading...' : 'Process'}
          </Button>
        </Col>
        <Row className="pe-0">
          <Col className="w-100 text-center pe-0">
            {localApprovedInvoices.length > 0 && (
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={() => setIsConfirmationModalOpen(true)}
                className="mb-3 w-100 pms-bg"
              >
                <strong>
                  {isLoading ? 'Saving...' : 'Upload to NetSuite!'}
                </strong>
              </Button>
            )}
          </Col>
        </Row>
      </Row>
      <Table
        columns={invoiceTableHeader}
        dataSource={data}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
      />
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setIsConfirmationModalOpen}
        title={'Upload to NetSuite?'}
        body={
          'Please confirm that all the data included on the table should be uploaded to NetSuite. REVERT IS NOT an option.'
        }
        handleConfirm={handleUploadToNetSuite}
        confirmationVariant={'danger'}
      />
    </Container>
  );
}
