import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Button,
  FloatingLabel,
  Form,
  InputGroup,
} from 'react-bootstrap';

import './login.css';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/contexts/UserContext';
import { login } from '../../utils/api/LoginAPI';
import { getAuthTokenNetSuite } from '../../utils/api/NetSuiteAPI';

export default function Login() {
  const { profile, setProfile } = useContext(UserContext);

  const [linkParams] = useSearchParams();
  const { isLoggedIn, setIsLoggedIn } = useContext(GlobalContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorAlertShown, setIsErrorAlertShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = await login(username, password);
    if (data.isSuccessful) {
      setProfile(data.userDetails[0]);
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  };

  const getCurrentUser = (user) => {
    if (user !== null && user !== undefined && user !== '') {
      setUsername(user);
    }
  };

  useEffect(() => {
    getAuthTokenNetSuite(linkParams.get('code'));
    getCurrentUser(linkParams.get('username'));
  }, []);

  return (
    <div className="Auth-form-container">
      <Form className="Auth-form p-5" onSubmit={handleSubmit}>
        <h3 className="text-secondary text-center">Confirm credentials</h3>
        <Form.Group className="mb-3" controlId="formBasicUserName">
          <FloatingLabel
            controlId="floatingUsernameInput"
            label="Username/Email"
            className="text-muted"
          >
            <Form.Control
              type="text"
              required={true}
              placeholder="Please enter your username/email."
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              disabled={isLoading ? true : false}
            />
          </FloatingLabel>
          <Form.Text className="text-muted">
            Please do not share your login credentials to anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <FloatingLabel
            controlId="floatingPasswordInput"
            label="Password"
            className="text-muted"
          >
            <Form.Control
              type="password"
              placeholder="Password"
              required={true}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              disabled={isLoading ? true : false}
            />
          </FloatingLabel>
        </Form.Group>
        <Alert
          variant="danger"
          show={isErrorAlertShown}
          onClose={() => setIsErrorAlertShown(false)}
          dismissible
          className="my-0"
        >
          {errorMessage}
        </Alert>
        <Button
          variant="secondary"
          type="submit"
          className="btn-block w-100 mt-3 py-2 pms-bg"
        >
          Sign In
        </Button>
      </Form>
    </div>
  );
}
