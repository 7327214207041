import { createContext, useState } from 'react';
import { getApprovedInvoices, getInvoices } from '../api/InvoiceAPI';
import { getBuildings } from '../api/BuildingAPI';
import { getApprovedCashSales, getCashSales } from '../api/CashSalesAPI';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [profile, setProfile] = useState({
    userId: '5',
    name: 'Administrator Billing',
    userLevel: 'bl',
    buildingId: '',
  });

  const [invoicesForApproval, setInvoicesForApproval] = useState([]);
  const [approvedInvoicesList, setApprovedInvoicesList] = useState([]);

  const [cashSalesForApproval, setCashSalesForApproval] = useState([]);
  const [approvedCashSalesList, setApprovedCashSalesList] = useState([]);

  const [buildings, setBuildings] = useState([]);

  const loadInvoicesForApproval = async (month, building) => {
    const invoices = await getInvoices(month, building);
    if (invoices.isSuccessful) {
      setInvoicesForApproval(invoices.invoiceReport);
    }
  };

  const loadApprovedInvoices = async (month, building) => {
    const approvedInvoices = await getApprovedInvoices(month, building);
    if (approvedInvoices.isSuccessful) {
      setApprovedInvoicesList(approvedInvoices.invoiceReport);
    }
  };

  const loadCashSalesForApproval = async (month, building) => {
    const cashSales = await getCashSales(month, building);
    if (cashSales.isSuccessful) {
      setCashSalesForApproval(cashSales.invoiceReport);
    }
  };

  const loadApprovedCashSales = async (month, building) => {
    const approvedCashSales = await getApprovedCashSales(month, building);
    if (approvedCashSales.isSuccessful) {
      setApprovedCashSalesList(approvedCashSales.invoiceReport);
    }
  };

  const loadBuildings = async () => {
    const buildings = await getBuildings();
    if (buildings.isSuccess === 'T') {
      setBuildings(buildings.buildings);
    }
  };

  const value = {
    profile,
    setProfile,
    invoicesForApproval,
    loadInvoicesForApproval,
    approvedInvoicesList,
    loadApprovedInvoices,
    cashSalesForApproval,
    loadCashSalesForApproval,
    approvedCashSalesList,
    loadApprovedCashSales,
    buildings,
    loadBuildings,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
